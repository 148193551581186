/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { auth, useAuth } from "gatsby-theme-firebase"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Button from "gatsby-theme-firebase/src/components/Button"
import LoginModal from "../components/LoginModal"
import Colors from "../components/colors"
import Member from "../components/Member"


const MembersPage = () => {
  const [toggleLogin, setToggleLogin] = useState(false);
  const { isLoading, isLoggedIn, profile } = useAuth();

  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  return(
    <StaticQuery query={graphql`
      query MemberQuery
       {
        allAirtable(filter: {table: {eq: "Members"}, data: {Active_Member_: {eq: true}}}, sort: {fields: data___Company_Name_Lookup, order:ASC}) {
          edges {
            node {
              data {
                Id
                Title
                api_member_name
                Email
                Cell__
                Membership
                Company_Name_Lookup
                seat_name
              }
            }
          }
        }
      }
    `}
      render={data => (
        <Layout resetPadding>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding:'2rem 3rem',
            height: '100%',
            width: '100%',
            backgroundColor: Colors.gray[0]
          }}>
          {
            isLoggedIn && profile.emailVerified ? (
              <div style={{maxWidth: 1000, width: '100%'}}>
                {
                  data &&
                  data.allAirtable.edges.map((edge) => {
                    const member = edge.node.data
                    return (
                      <Member member={member} showCompany/>
                    )
                  })
                }
              </div>
            )
            : (
              <div>
                <h1>You must be logged in to see this page.</h1>
                <Button
                  onClick={() => {
                    setToggleLogin(true);
                  }}
                >
                  Login
                </Button>
              </div>
            )
          }
          {toggleLogin && <LoginModal setToggleLogin={setToggleLogin} />}
          </div>
        </Layout>
      )}
    />
  )
  
}

export default MembersPage
